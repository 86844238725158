import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../shared/styling/PageBase.css';
import { Component } from 'react';
import Portfolio from './Portfolio'
import CompilerDemo from './CompilerDemo';
import CardGameDemo from './CardGameDemo';
import PhonebookDemo from './PhonebookDemo';
import VirtualBankDemo from './VirtualBankDemo';
import CalculatorDemo from './CalculatorDemo';
import ClientServerDemo from './ClientServerDemo';

class PortfolioHome extends Component {
    render() {
        return <div>
          <Route exact path={this.props.match.path} component={Portfolio} />
          <Route path={`${this.props.match.path}/compiler-demo`} component={CompilerDemo} />
          <Route path={`${this.props.match.path}/card-game-demo`} component={CardGameDemo} />
          <Route path={`${this.props.match.path}/phonebook-demo`} component={PhonebookDemo} />
          <Route path={`${this.props.match.path}/bank-demo`} component={VirtualBankDemo} />
          <Route path={`${this.props.match.path}/calculator-demo`} component={CalculatorDemo} />
          <Route path={`${this.props.match.path}/client-server-demo`} component={ClientServerDemo} />
        </div>
      }
    }

export default PortfolioHome;