import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';
import VirtualBank from '../shared/vid/bank.mp4';

function VirtualBankDemo() {
        return (
            <div className="demo_page2">
                <BackBar/>
                <h2>B A N K&nbsp;&nbsp;D E M O</h2>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={VirtualBank} type="video/mp4"/>
                    </video>
                </div>
                <p>A Java Swing GUI online banking system where the user can create new bank accounts and deposit, withdraw, and update them as required. This system can also read in bank accounts from text files.</p>
            </div>
        );
}

export default VirtualBankDemo;