import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';
import CalculatorVideo from '../shared/vid/Calculator.mp4'

function CalculatorDemo() {
        return (
            <div className="demo_page2">
                <BackBar/>
                <h2>C A L C U L A T O R&nbsp;&nbsp;D E M O</h2>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={CalculatorVideo} type="video/mp4"/>
                    </video>
                </div>
                <p>A Java Swing GUI calculator that can calculate in floating point, integer, or scientific notation.</p>
            </div>
        );
}

export default CalculatorDemo;