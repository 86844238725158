import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';
import Phonebook from '../shared/vid/binary.mp4';

function PhonebookDemo() {
        return (
            <div className="demo_page2">
                <BackBar/>
                <h2>E M E R G E N C Y&nbsp;&nbsp;C O N T A C T&nbsp;&nbsp;D E M O</h2>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={Phonebook} type="video/mp4"/>
                    </video>
                </div>
                <p>A Java console application that simulates an emergency contact system where contacts are determined through various binary tree traversals.</p>
            </div>
        );
}

export default PhonebookDemo;