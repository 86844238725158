import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';
import Game from '../shared/vid/game.mp4';

function CardGameDemo() {
        return (
            <div className="demo_page2">
                <BackBar/>
                <h2>C A R D &nbsp;&nbsp;G A M E&nbsp;&nbsp; D E M O</h2>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={Game} type="video/mp4"/>
                    </video>
                </div>
                <p>This game was created in C++ using the SFML library. To win, the user must flip two cards at once. If the cards match, they disappear. If the cards do not match, they flip back over. The user wins when they have matched all ten pairs.</p>
            </div>
        );
}

export default CardGameDemo;