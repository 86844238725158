import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import Example from '../shared/img/example.PNG'
import Stage1f from '../shared/img/ass1f.PNG'
import Stage1a from '../shared/img/ass1a.PNG'
import Stage1m from '../shared/img/ass1m.PNG'
import Stage2 from '../shared/vid/ass2.mp4'
import Stage3 from '../shared/vid/ass3.mp4'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';

function CompilerDemo() {
        return (
            <div className="demo_page">
                <BackBar/>
                <h2>C O M P I L E R&nbsp;&nbsp;D E M O</h2>
                <p>This is a three-stage front-end compiler created with ANSI C to compile a fictional simple programming language called PLATYPUS. Here is a screen capture of the text file used in this demonstration:</p>
                <img src={Example}></img>
                <h3>C OM P I L E R&nbsp;&nbsp;S T A G E&nbsp;&nbsp;1:</h3>
                <h3>B U F F E R</h3>
                <p>The first stage of the PLATYPUS compiler is the buffer. This buffer has three modes: fixed, additive, and multiplicative. This buffer’s size is allocated dynamically and can read and store the characters of any text file. It also counts the number of characters read. It utilizes two hexadecimal flags. The first is an end-of-buffer bit which is flipped when the buffer has reached capacity. The second is a single-bit reallocation flag which indicates if the memory location of the buffer has changed.</p>
                <p>Here is the output from fixed, additive, and multiplicative mode:</p>
                <div className="image_set">
                <img src={Stage1f}></img>
                <img src={Stage1a}></img>
                <img src={Stage1m}></img>
                </div>
                <h3>C OM P I L E R&nbsp;&nbsp;S T A G E&nbsp;&nbsp;2:</h3>
                <h3>S C A N N E R</h3>
                <h4>(Lexical Analyzer)</h4>
                <p>The second stage of the PLATYPUS compiler is the scanner. The scanner is implemented using deterministic finite automation (DFA) based on a transition table. Lexical grammar is converted into regular expressions, then into a transition diagram, and finally into a transition table. Sequences of characters are translated into tokens categorized by, for example, a string literal, floating point literal, integer, logical operator, arithmetic operator, etc. based upon the character sequence specified in the grammar.</p>
                <p>Here is a video demo of the scanner translating each sequence of characters from the example text file into its appropriate token:</p>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={Stage2} type="video/mp4"/>
                    </video>
                </div>
                <h3>C OM P I L E R&nbsp;&nbsp;S T A G E&nbsp;&nbsp;3:</h3>
                <h3>P A R S E R</h3>
                <h4>(Recursive Descent Predictive Parser (RDPP))</h4>
                <p>The third and final stage of the PLATYPUS compiler is the parser. The parser translates the syntactic grammar into the appropriate sets, then recursively matches token sequences to the appropriate sets by recursively calling down set-specific functions. The parser also prints an error when a token is not appropriate for a particular sequence, without overrunning the entire contents of the buffer.</p>
                <p>Here is a video demo of the parser separating token sequences and printing when the associated set has been processed:</p>
                <div className="video_bottom">
                    <video width="600" height="400" controls>
                        <source src={Stage3} type="video/mp4"/>
                    </video>
                </div>
            </div>
        );
}

export default CompilerDemo;