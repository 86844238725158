import React from 'react';
import NavBar from './NavigationBar'
import { Container } from 'react-bootstrap';
import '../shared/styling/Home.css';

export default class Home extends React.Component {
    render() {
        return (
            <div class="home_page">
            <NavBar/>
                <ul class="header-text">
                    <li>W</li>
                    <li>E</li>
                    <li>L</li>
                    <li>C</li>
                    <li>O</li>
                    <li>M</li>
                    <li>E</li>
                    </ul>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
            </div>
        )
    }
}