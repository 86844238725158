import React, { Component } from 'react'
import '../shared/styling/PageBase.css'
import NavBar from '../components/NavigationBar'
import BackBar from '../components/BackBar';
import ClientServer from '../shared/vid/clientserver.mp4'

function ClientServerDemo() {
        return (
            <div className="demo_page2">
                <BackBar/>
                <h2>C L I E N T&nbsp;&nbsp;S E R V E R&nbsp;&nbsp;D E M O</h2>
                <div className="video">
                    <video width="600" height="400" controls>
                        <source src={ClientServer} type="video/mp4"/>
                    </video>
                </div>
                <p>A Java socket programming GUI that can handle one or many simultaneous clients sending and receiving from one or many simultaneous servers.</p>
            </div>
        );
}

export default ClientServerDemo;