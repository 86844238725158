import React, { Component } from 'react'
import '../shared/styling/Portfolio.css'
import NavBar from '../components/NavigationBar'
import { Link } from 'react-router-dom'
import { Breakpoint, setDefaultBreakpoints } from 'react-socks';
import { Container, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const compilerTooltip = <Tooltip><b>PLATYPUS Compiler</b><br/><em>ANSI C</em><br/></Tooltip>
const cardGameTooltip = <Tooltip><b>Match The Pairs</b><br/><em>C++</em><br/></Tooltip>
const binaryTreeTooltip = <Tooltip><b>Binary Tree Emergency Contact System</b><br/><em>Java</em><br/></Tooltip>
const bankingTooltip = <Tooltip><b>Banking System GUI</b><br/><em>Java</em><br/></Tooltip>
const calculatorTooltip = <Tooltip><b>Calculator GUI</b><br/><em>Java</em><br/></Tooltip>
const socketTooltip = <Tooltip><b>Client/Server GUI</b><br/><em>Java</em><br/></Tooltip>

const renderTooltip = props => (
    <Tooltip>{props}</Tooltip>
);

setDefaultBreakpoints([
    { small: 0 },
    { medium: 550 },
    { large: 980 },
    { xlarge: 1600 }
]);

function circleImageFormation() {

    return (
        <div>
        <Row>
            <Link to="/portfolio/compiler-demo">
        <OverlayTrigger placement="bottom" overlay={compilerTooltip}>
             <Button className="first_img">Compiler</Button>
        </OverlayTrigger>
        </Link>
        <Link to="/portfolio/card-game-demo">
        <OverlayTrigger placement="bottom" overlay={cardGameTooltip}>
             <Button>Card Game</Button>
        </OverlayTrigger>
        </Link>
    </Row>
    <Row>
        <div class="row_2">
        <Link to="/portfolio/phonebook-demo">
        <OverlayTrigger placement="bottom" overlay={binaryTreeTooltip}>
             <Button>Contacts</Button>
        </OverlayTrigger>
        </Link>
        <h2 class="neon_projects">Projects</h2>
        <Link to="/portfolio/bank-demo">
        <OverlayTrigger placement="bottom" overlay={bankingTooltip}>
             <Button>Bank</Button>
        </OverlayTrigger>
        </Link>

        </div>
    </Row>
    <Row>
        <div class="row_2">
        <Link to="/portfolio/calculator-demo">
        <OverlayTrigger placement="top" overlay={calculatorTooltip}>
             <Button className="first_img">Calculator</Button>
        </OverlayTrigger>
        </Link>
        <Link to="/portfolio/client-server-demo">
        <OverlayTrigger placement="top" overlay={socketTooltip}>
             <Button>Client/Server</Button>
        </OverlayTrigger>
        </Link>
        </div>
    </Row>
    </div>
    );
}

function stackImageFormation() {
    return (
        <div>
    <Row>
        <h2 class="neon_projects">Projects</h2>
    </Row>
    <Row>
    <Link to="/portfolio/compiler-demo">
        <OverlayTrigger placement="top" overlay={compilerTooltip}>
             <Button className="first_img">Compiler</Button>
        </OverlayTrigger>
        </Link>
        <Link to="/portfolio/card-game-demo">
        <OverlayTrigger placement="top" overlay={cardGameTooltip}>
             <Button>Card Game</Button>
        </OverlayTrigger>
        </Link>
    </Row>
    <Row>
    <Link to="/portfolio/phonebook-demo">
        <OverlayTrigger placement="top" overlay={binaryTreeTooltip}>
             <Button className="first_img">Contacts</Button>
        </OverlayTrigger>
        </Link>
        <Link to="/portfolio/bank-demo">
        <OverlayTrigger placement="top" overlay={bankingTooltip}>
             <Button>Bank</Button>
        </OverlayTrigger>
        </Link>
    </Row>
    <Row>
    <Link to="/portfolio/calculator-demo">
        <OverlayTrigger placement="top" overlay={calculatorTooltip}>
             <Button className="first_img">Calculator</Button>
        </OverlayTrigger>
    </Link>
    <Link to="/portfolio/client-server-demo">
        <OverlayTrigger placement="top" overlay={socketTooltip}>
             <Button>Client/Server</Button>
        </OverlayTrigger>
    </Link>
    </Row>  
    </div>      
    );
}

function Portfolio() {
        return (
            <div className="projects_page">
            <NavBar/>
            <Container>
            <Breakpoint xlarge up>
            <div class="xlarge_dim">
                {circleImageFormation()}
           </div>  
            </Breakpoint>
            <Breakpoint large >
            <div class="large_dim">
                {circleImageFormation()}
           </div>
            </Breakpoint>
            <Breakpoint medium>
            <div class="medium_dim">
                {circleImageFormation()}
           </div>
            </Breakpoint>
            <Breakpoint small>
            <div class="small_dim">
                {stackImageFormation()}
            </div>
            </Breakpoint>
            </Container>
            </div>
        );
}

export default Portfolio;