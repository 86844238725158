import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { BreakpointProvider } from 'react-socks';
import Home from './components/Home';
import About from './components/About';
import PortfolioHome from './portfolio/PortfolioHome';

class App extends React.Component {
  render() {
    return (
      <BreakpointProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/portfolio" component={PortfolioHome} />
          <Route component={Home}/>
          </Switch>
      </BrowserRouter>
      </BreakpointProvider>
    );
  }
}

export default App;
