import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import '../shared/styling/Bar.css';

function NavigationBar() {
    return (
        <div class="navbar">
            <div class="home">
            <Link to="/">
            <Button>HOME</Button>
            </Link>
            </div>
            <div class="button_group">
            <Link to="/about">
            <Button>ABOUT</Button>
            </Link>
            <Link to="/portfolio">
            <Button>PORTFOLIO</Button>
            </Link>
            </div>
        </div>
    );
}

export default NavigationBar;