import React from 'react'
import '../shared/styling/PageBase.css'
import NavBar from './NavigationBar'
import { Container } from 'react-bootstrap';

function About() {
        return (
            <div class="about_page">
            <NavBar/>
            <Container>
                    <h2>A B O U T&nbsp;&nbsp;T A Y L O R</h2>
                    <p>Taylor is in her final year of the Computer Engineering Technology Program at Algonquin College. Some of her personal highlights in this program include learning Data Structures and Algorithms, Processor Architecture, Java, C, C++, .NET, Numerical Computing, Compilers, and Interfacing. Taylor started off in the arts, attending Canterbury Arts High School in the Visual Arts Program, then transitioned into the Social Sciences at the University of Ottawa and graduated with an Honours Bachelor’s of Criminology and Psychology, before finding her ultimate passion in programming. Taylor is particularly interested in combining her creativity, knowledge about the human mind, and passion for programming into cracking the code of AI decision trees, HCI, and game theory implementation in AI. Specifically, she is keen to discover and create algorithms that can produce human-like decision trees to mimic empathy in HCI’s.
                    </p>
            </Container>
            </div>
        );
}

export default About;